<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card class="px-2 py-4 rounded-lg" id="card-custom">
          <p class="headline-color px-2">Generate SKP Bulanan</p>
          <v-divider class="mb-3" />
          <v-form
            id="form"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitFilter()"
          >
            <v-row>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="py-0">
                <v-autocomplete
                  :items="units"
                  v-model="unit"
                  :loading="loadingUnit"
                  :rules="[v => !!v || 'Unit Utama harus diisi']"
                  placeholder="Unit Utama"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  clearable
                  @change="changeUnit(1)"
                  @click:clear="changeUnit(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="py-0">
                <v-autocomplete
                  :items="units2"
                  v-model="unit2"
                  :loading="loadingUnit"
                  :rules="[v => !!v || 'Unit Kerja 2 harus diisi']"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  clearable
                  @change="changeUnit(2)"
                  @click:clear="changeUnit(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="py-0">
                <v-autocomplete
                  :items="units3"
                  v-model="unit3"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  clearable
                  @change="changeUnit(3)"
                  @click:clear="changeUnit(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="py-0">
                <v-autocomplete
                  :items="units4"
                  v-model="unit4"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="py-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      placeholder="Bulan"
                      append-icon="event"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    locale="id"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn text color="primary" @click="$refs.menu.save(date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                class="py-0"
              >
                <v-card-actions>
                  <v-spacer />
                  <div class="pr-5" v-if="achievements.length != 0">
                    <v-switch
                      v-model="bulkSync"
                      label="Bulk Sync"
                      color="primary"
                    ></v-switch>
                  </div>
                  <v-btn
                    color="primary"
                    class="px-10"
                    :loading="loadingSync"
                    :disabled="achievements.length == 0"
                    @click="syncSKP"
                  >
                    <span class="subtitle-2 text-capitalize">Sync</span>
                  </v-btn>
                  <v-btn
                    color="#FBB005"
                    class="px-10"
                    :loading="loading"
                    type="submit"
                    :disabled="!valid"
                    form="form"
                  >
                    <span class="subtitle-2 text-capitalize">Cari</span>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            id="table-custom"
            class="mt-2"
            multi-sort
            :headers="headers"
            :items="achievements"
            :loading="loading"
            :options.sync="options"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.no`]="{ item }">
              <span>{{ item.no }}</span>
            </template>
            <template v-slot:[`item.nilai_capaian`]="{ item }">
              <v-progress-linear
                v-if="item.loading"
                indeterminate
                color="cyan"
              ></v-progress-linear>
              <span v-else>
                <span v-if="item.error">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="error">info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.error }}</span>
                  </v-tooltip>
                </span>
                <span v-else>{{ item.nilai_capaian }}</span>
              </span>
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Data capaian kerja tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="loadingSync" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Harap tunggu, sedang memproses singkronisasi nilai capaian kerja
        </v-card-text>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import SKPService from "@/services/resources/skp.service";
const ContentNotFound = () => import("@/components/Content/NotFound");

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      bulkSync: true,
      loadingUnit: false,
      loadingSync: false,
      valid: false,
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      headers: [
        { text: "NOMOR", value: "no", sortable: false, align: "left" },
        { text: "NIP", value: "NIP", sortable: false, align: "left" },
        { text: "NAMA", value: "nama", sortable: false, align: "left" },
        { text: "BULAN", value: "period_name", sortable: false, align: "left" },
        {
          text: "NILAI CAPAIAN KERJA",
          value: "nilai_capaian",
          sortable: false,
          align: "left"
        }
      ],
      menu: false,
      date: new Date().toISOString().substr(0, 7),
      totalItem: 0,
      pageCount: 0,
      loading: false,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      achievements: []
    };
  },
  watch: {
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    }
  },
  methods: {
    submitFilter() {
      if (this.$refs.form.validate()) {
        this.getListCapaianKerja();
      }
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    syncSKP() {
      if (this.bulkSync) {
        this.syncCapaianKerjaBulk();
      } else {
        this.achievements.map((item, index) =>
          this.syncCapaianKerja(item, index)
        );
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async syncCapaianKerja(item, index) {
      const { NIP } = item;
      try {
        this.achievements[index].loading = true;
        this.achievements[index].error = null;
        await SKPService.sync({
          NIP,
          period: this.$moment(this.date).format("YYYYMM")
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const { nilai_capaian, status: statusCode } = data;
              if (statusCode == "") {
                this.achievements[index].nilai_capaian = nilai_capaian;
              } else {
                this.achievements[index].error = statusCode;
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.achievements[index].loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async syncCapaianKerjaBulk() {
      try {
        this.loadingSync = true;
        await SKPService.bulkSync({
          filter: {
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          period: this.$moment(this.date).format("YYYYMM")
        })
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListCapaianKerja();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingSync = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListCapaianKerja() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await SKPService.getList({
          filter: {
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          period: this.$moment(this.date).format("YYYYMM"),
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.achievements = data.map((item, index) => {
                item.no = index + 1;
                item.loading = false;
                return item;
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #f5f6fa;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #a3a6b4;
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
