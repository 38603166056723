import BaseInstance from "../base.instance";
import {
  PRESENSI_SKP_LIST_DATA,
  PRESENSI_SKP_SYNCHRON_DATA,
  PRESENSI_SKP_SYNCHRON_DATA_BULK
} from "../constants";

const SKPService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_SKP_LIST_DATA, data);
  },
  sync(data) {
    return BaseInstance.post(PRESENSI_SKP_SYNCHRON_DATA, data);
  },
  bulkSync(data) {
    return BaseInstance.post(PRESENSI_SKP_SYNCHRON_DATA_BULK, data);
  }
};

export default SKPService;
